import React from 'react'
import Link from '../../utilities/link'
import ModaWithButton from '../common/modal'
import LaundryNews from '../forms/laundryNewsForm'
import { useTranslation } from 'react-i18next'
import HorizontalSubForm from '../forms/horizontalSubForm'
import CookieDialog from '../common/cookieDialog'
import Chat from '../common/chat'
import { StaticImage } from 'gatsby-plugin-image'

const CommonFooter = () =>  {

    const { t } = useTranslation()

    return (
      <>
        {/* <Chat /> */}
        <CookieDialog />
        <div className="bg-gray-50 py-48 border-b-4 border-gray-700">
          <div className="px-8 md:px-0 md:max-w-7xl mx-auto">
            <HorizontalSubForm />
          </div>
        </div>
          <Link
            to={`/contatti`}
            title={t('header.ContactUsLinkTitle')}
            className="absolute right-0 -mt-8 greenBtn"
          >
            {t('footer.Button')}
          </Link>
        <footer className="bg-gray-50">
          <div className="section">
            <div className="flex flex-col md:flex-row justify-between space-y-8 md:space-y-0 py-24">
              <div className='md:w-2/5'>
                <div className="font-semibold text-xl">
                  CTA Electronics
                </div>
                <h3 className="font-semibold mt-4">
                  {t('footer.Description')}
                </h3>
                <div className="md:flex md:space-x-8 mt-8">
                  <div className='md:w-1/2'>
                    <p className='mb-4 py-2 font-semibold border-b'>Indirizzo e Dati Anagrafici</p>
                    <p className='text-gray-700 text-sm'>
                      Via Quarto 26
                      <br />
                      20811 Cesano Maderno (MB)
                      <br />
                      Italia
                      <br />
                      <strong>P.IVA</strong> IT09176980960 <br />
                      <strong>CF</strong> 09176980960 <br />
                    </p>
                  </div>
                  <div className='md:w-1/2'>
                    <p className='mb-4 py-2 font-semibold border-b'>Contatti e Recapiti</p>
                    <p className='text-sm'>
                    <strong>Tel</strong> +39 0362 54 11 16 <br />
                    <strong>Email</strong> info[at]ctaelectronics[dot]com <br />
                    <strong>Fax</strong> +39 0362 54 11 23 <br />
                    <br />
                    <strong>Linkedin</strong>{' '}
                    <a
                      href="https://www.linkedin.com/company/10507408"
                      title="CTA Linkedin Page"
                      style={{ color: '#4a4a4a' }}
                    >
                      {t('footer.linkedin')}
                    </a>
                  </p>
                </div>
                </div>
              </div>
              <div className='md:flex space-y-8 md:space-y-0 justify-around'>
                <div className='flex flex-col'>
                  <Link
                    to={`/chi-siamo`}
                    title={t('header.AboutLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.About')}
                  </Link>
                  <Link
                    to={`/contatti`}
                    title={t('header.ContactUsLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.ContactUs')}
                  </Link>
                  <Link
                    to={`/settori`}
                    title={t('header.SettoriLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.Industries')}
                  </Link>
                  <Link
                    to={`/lavora-con-noi`}
                    title={t('header.CareersLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.Careers')}
                  </Link>
                  <Link
                    to={`/lavora-con-noi/stage`}
                    title={t('header.StageLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.Internships')}
                  </Link>
                  <Link
                    to={`/privacy`}
                    title="Privacy Policy"
                    className="footerlink"
                  >
                    {'>'} {t('footer.Policy')}
                  </Link>
                  <Link
                    to={`/condizioni-di-vendita`}
                    title="Condizioni di Vendita"
                    className="footerlink"
                  >
                    {'>'} {t('footer.CondizioniVendita')}
                  </Link>
                </div>
                <div className='flex flex-col'>
                  <Link
                    to={`/prodotti/hera-laundry`}
                    title={t('header.HeraLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.Hera')}
                  </Link>
                  <Link
                    to={`/prodotti/fiber-el6`}
                    title={t('header.EL6LinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.EL6')}
                  </Link>
                  <Link
                    to={`/prodotti/schede-rele-interfaccia-plc`}
                    title={t('header.IOLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.IOBoards')}
                  </Link>
                  <Link
                    to={`/prodotti/sonde-di-temperatura`}
                    title={t('header.SensoriLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.Sensors')}
                  </Link>           
                </div>
                <div className="flex flex-col">
                  <Link
                    to={`/servizi/progettazione-elettronica`}
                    title={t('header.ProgettazioneLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.ElectronicsDesign')}
                  </Link>
                  <Link
                    to={`/servizi/sviluppo-firmware`}
                    title={t('header.SviluppoFirmwareLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.FirmwareDevelopment')}
                  </Link>
                  <Link
                    to={`/servizi/sviluppo-software`}
                    title={t('header.SviluppoSoftwareLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.SoftwareDevelopment')}
                  </Link>
                  <Link
                    to={`/servizi/progettazione-elettronica/nuovo-progetto`}
                    title={t('header.ProgettoLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.ProjectBoost')}
                  </Link>
                  <Link
                    to={`/servizi/produzione-schede-elettroniche`}
                    title={t('header.ProduzioneLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.ContractManufacturing')}
                  </Link>
                  <Link
                    to={`/servizi/conformal-coating`}
                    title={t('header.CoatingLinkTitle')}
                    className="footerlink"
                  >
                    {'>'} {t('header.ConformalCoating')}
                  </Link>    
                </div>
              </div>
            </div>
            <div className="text-sm w-full text-center mt-8 border-t-2 pt-4 border-gray-100">
              <p>
                <b>CTA Electronics Srl</b> •{' '}
                <i>Italian Excellence in Industrial Electronics</i> •
                Copyright {new Date().getFullYear()} • All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </>
    )
  }


export default CommonFooter
